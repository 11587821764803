/* eslint-disable import/no-anonymous-default-export */
import Page from 'components/page';
import ImageModal from "components/imagemodal";
import { useTranslation } from 'react-i18next';

import i5 from 'assets/i5.png';
import i6 from 'assets/i6.png';

export default () => {
    const { t } = useTranslation();
    
    return (<Page>
    <div className="h-80 d-flex text-center flex-column justify-content-center h-100">
        <ImageModal src={i6} fluid className="cover w-100"/>
        <div>
            <p className="px-3 pt-3">{t("our_service_1")}</p>
            <p className="px-3 pb-4">{t("our_service_2")}</p>
        </div>
        <ImageModal src={i5} fluid className="cover w-100"/>
        <p className="px-3 pt-4 pb-5">{t("the_island")}</p>
    </div>
    </Page>)
};