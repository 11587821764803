/* eslint-disable import/no-anonymous-default-export */
import Page from 'components/page';
import ImageModal from "components/imagemodal";
import { useTranslation } from 'react-i18next';

import i3 from 'assets/i3.gif';

export default () => {
    const { t } = useTranslation();
    
    return (<Page>
    <div className="d-flex text-center flex-column justify-content-center h-100">
        
        <div className="pt-2 pb-5">
            <h1>S'agaró</h1>
            <p style={{   fontSize:"1.5rem", fontWeight:800}}>Llaüt Charter Yatch</p>
            <p className="px-3 pt-3"> {t("llaut_intro")} </p>
        </div>

        <ImageModal src={i3} fluid className="cover w-100"/>
    </div>
    </Page>)
};