/* eslint-disable import/no-anonymous-default-export */
import Page from 'components/page';
import ImageModal from "components/imagemodal";
import { useTranslation } from 'react-i18next';

import i2 from 'assets/i2.gif';

export default () => {
    const { t } = useTranslation();
    
    return (<Page>
    <div className="h-80 d-flex text-center flex-column justify-content-center h-100">
        
        <h1 className="pt-3" id="mallorcatitle">
            Mallorca
        </h1>
        <p className="px-3 pb-4"> {t("mallorca_intro")} </p>
        <ImageModal src={i2} fluid className="cover w-100"/>
        <p className="px-3 pt-3 pb-3"> {t("mallorca_second")}</p>
    </div>
    </Page>)
};