/* eslint-disable import/no-anonymous-default-export */
import Page from 'components/page';
import ImageModal from "components/imagemodal";
import { useTranslation } from 'react-i18next';

import i4 from 'assets/i4.png';


export default () => {
    const { t } = useTranslation();

    return (<Page >
    <div className="h-80 d-flex text-center flex-column justify-content-center h-100">

        <h3 className="pt-4" style={{fontSize:"3.5rem"}}>
            {t("contacto")}
            <p style={{fontSize:"2rem"}}><a href="tel:+34 658 48 43 01">+34 658 48 43 01</a> <b>Pepe</b></p>
        </h3>
        
        <ImageModal src={i4} fluid className="cover w-100"/>

        <div className="pt-4 pb-3">
            <a  alt="instagram" href="https://www.instagram.com/llautchartermallorca/" target="_blank" rel="noreferrer">
                <i className="bi bi-instagram" style={{verticalAlign: "-.125em",fontSize:"3rem", color:"white", background:"#5D3429", padding:"10px 12px 6px 12px", borderRadius:"16px", width:"84px"}}/>
                <h2 style={{fontSize:"1.75em", fontWeight:600}}>@llautchartermallorca</h2>
            </a>
            <a href="mailto:llautchartermallorca@gmail.com"><h2 style={{fontSize:"1.25em"}}>llautchartermallorca@gmail.com</h2></a> 
            <a href="/"> <h2 style={{fontSize:"1.25em"}}>www.llautchartermallorca.com</h2></a> 
        </div>

    </div>
</Page>)};