/* eslint-disable import/no-anonymous-default-export */
import Page from 'components/page';
import ImageModal from "components/imagemodal";
import { useTranslation } from 'react-i18next';

import i7 from 'assets/i7.png';
import i8 from 'assets/i8.png';

export default () => {
    const { t } = useTranslation();
    
    return (<Page>
    <div className="h-80 d-flex text-center flex-column justify-content-center h-100">
        
        <ImageModal src={i7} fluid className="cover w-100"/>
        
        <h3 className="mt-3">
            {t("aforo_maximo")}
            <br/>8
        </h3>
        
        <div style={{ padding:".5rem 1.5rem"}}>
            <h3>{t("equipamiento")}</h3>
            <p>{t("cosas")}</p>
        </div>

        <ImageModal src={i8} fluid className="cover w-100"/>

    </div>
    </Page>)
};