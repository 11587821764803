/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import { Image, Modal } from "react-bootstrap";

export default ({...props})=>{
    const [show, setShow] = useState(false);

    return (
        <>
            <Modal show={show} onHide={ () => setShow(false) }
                style={{
                    width: "100vw",
                    height: "100vh",
                }}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Image {...props} rounded onClick={ () => setShow(false) }  style={{border:"none"}}/>
            </Modal>

            <Image {...props} onClick={ () => setShow(true) }/>
        </>
    );
}