/* eslint-disable import/no-anonymous-default-export */
import "scss/page.scss";
import { Col } from "react-bootstrap";

export default ({...props}) => {return <>
    <Col
    xs={12} md={6} lg={6} xl={4} xxl={3}
    //sm={12} md={6} lg={6} xl={4} xxl={3}
    className="g-0 page d-flex flex-column" 
    {...props}/>
</>};