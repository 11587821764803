/* eslint-disable import/no-anonymous-default-export */
import { Helmet } from "react-helmet";
import { Container, Row } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";

import './scss/app.scss';

import P1 from "pages/p1.js";
import P2 from "pages/p2.js";
import P3 from "pages/p3.js";
import P4 from "pages/p4.js";
import P5 from "pages/p5.js";
import P6 from "pages/p6.js";
import LangSelector from "components/lang";


export default () => {
  const wrapperRef = useRef();

  // eslint-disable-next-line
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount( c => c + 1 );
  }, []);

  let pages = [
    <P3 />,
    <P4 />,
    <P5 />,
    <P6 />,
    <P2 />,
    <P1 />,
  ];

  useEffect(() => {
    if (!wrapperRef.current)
      return;
    wrapperRef.current.removeEventListener('wheel', onScroll);
    wrapperRef.current.addEventListener('wheel', onScroll);
  }, [wrapperRef]);

  function onScroll(e) {
    e.preventDefault();
    const amount = wrapperRef.current.scrollWidth / wrapperRef.current.childElementCount;
    wrapperRef.current.scrollLeft += (e.deltaY < 0) ? amount : -amount;
  }

  return (
    <>
      <Helmet>
        <title>S'agaró</title>
        <meta content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=0" name='viewport' />
      </Helmet>
      <LangSelector/>
      <Container fluid className="App">
        <Row ref={wrapperRef} className="wrapper flex-nowrap">
          {pages.map((v, k, a) => v)}
        </Row>
      </Container>
    </>);
}
