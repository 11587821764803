/* eslint-disable import/no-anonymous-default-export */
import i18n from "i18next";
import { useReducer, useEffect } from "react";
import { Image, Dropdown } from "react-bootstrap";

import { getCookie, setCookie } from "utils/cookies.js";
import esFlag from "assets/es-flag.png";
import enFlag from "assets/en-flag.png";

export default () => {

    const [lang, setLang] = useReducer( (old_value, new_value) => {
        i18n.changeLanguage( new_value );
        return new_value;
    }, "en");

    //Initialize current browsers language
    useEffect( () => {
        console.log("initialize");
        const locale = getCookie("lang") ?? Intl.NumberFormat().resolvedOptions().locale ?? "en";
        setLang(locale);
    }, []);

    const flags = {es: esFlag, en: enFlag};

    function handleChange(key, e)
    {
        let value = e.target.getAttribute("value");
        setLang( value );
        i18n.changeLanguage( value );
        setCookie("lang", value);
    }

    return <Dropdown autoClose className="px-2 py-2" onSelect={handleChange} style={{position:"fixed", top:0, left:0}}>
        <Dropdown.Toggle variant="outline-dark" size="sm" id="dropdown-basic">
            <Image src={flags[lang.substr(0,2)]} alt={`${lang.substr(0,2)} flag`}/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            { Object.entries(flags).map(([key, value]) =>
                <Dropdown.Item key={key} value={key} style={{textTransform: "capitalize"}}> <Image src={flags[key]} alt={`${key} flag`} value={key}/> {key} </Dropdown.Item>
            )}
        </Dropdown.Menu>
    </Dropdown>

}