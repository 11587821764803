/* eslint-disable import/no-anonymous-default-export */
import Page from 'components/page';
import ImageModal from "components/imagemodal";
import { useTranslation } from 'react-i18next';

import i1 from 'assets/i1.gif';

export default () => {

    const { t } = useTranslation();

    return (<Page>
    <div className="h-80 d-flex text-center flex-column justify-content-end h-100">
        <ImageModal src={i1} fluid className="cover w-100"/>
        <div className="pb-5">
            <h3 className="pt-0" style={{fontSize:"3.5rem"}}> {t("prices")}:</h3>
            <span className="pt-1"> {t("media jornada")}: </span>
            <h3><span style={{fontSize:"1.25rem"}}> {t("desde")} </span>350€</h3>
            <span className="pt-1"> {t("jornada completa")}: </span>
            <h3><span style={{fontSize:"1.25rem"}}> {t("desde")} </span>650€</h3>
        </div>
    </div>
</Page>)};